/**
 * 猿宝矩阵-路由配置
 * @author lb
 */
import LayoutIndex from '@/layout/Index';

const systemRouter = {
    path: '/mashup',
    component: LayoutIndex,
    redirect: '/mashup/script',
    name: 'mashup',
    meta: {
        title: '猿宝矩阵',
        icon: 'page',
        roles: ['mashup']
    },
    children: [
        {
            path: 'script',
            component: () => import('@/views/Mashup/Script'),
            name: 'script-list',
            meta: { title: '脚本管理', roles: ['mashup-script-list'] }
        },
        {
            path: 'task',
            component: () => import('@/views/Mashup/Task'),
            name: 'task-list',
            meta: { title: '混剪任务', roles: ['mashup-task-list'] }
        },
        {
            path: 'audio',
            component: () => import('@/views/Mashup/Audio'),
            name: 'audio-list',
            meta: { title: '语音合成模板管理', roles: ['mashup-audio-list'] }
        }
    ]
};

export default systemRouter;
