/**
 * 内容管理-路由配置
 * @author lb
 */
import LayoutIndex from '@/layout/Index';

const systemRouter = {
    path: '/content',
    component: LayoutIndex,
    redirect: '/content/title',
    name: 'content',
    meta: {
        title: '内容管理',
        icon: 'page',
        roles: ['content']
    },
    children: [
        {
            path: 'title',
            component: () => import('@/views/Headlines/Title'),
            name: 'title',
            meta: { title: '短文管理' }
        },
        {
            path: 'book',
            component: () => import('@/views/Headlines/Book'),
            name: 'book',
            meta: { title: '书籍管理', roles: ['content-book-list'] }
        },
        {
            path: 'chapter',
            component: () => import('@/views/Headlines/Chapter'),
            name: 'chapter',
            meta: { title: '章节管理', roles: ['content-chapter-list'] }
        },
        {
            path: 'ai',
            component: () => import('@/views/Headlines/AI'),
            name: 'ai',
            meta: { title: 'AI内容管理', roles: ['content-ai-list'] }
        },
        {
            path: 'video',
            component: () => import('@/views/Headlines/Video'),
            name: 'video',
            meta: { title: '视频合成记录', roles: ['content-video-list'] }
        },
        {
            path: 'xh',
            component: () => import('@/views/Headlines/XH'),
            name: 'xh',
            meta: { title: '讯飞星火' }
        },
        {
            path: 'file',
            component: () => import('@/views/Headlines/File'),
            name: 'file-list',
            meta: { title: '文件管理', roles: ['content-file-list'] }
        }
    ]
};

export default systemRouter;
