/**
 * 抖音管理-路由配置
 * @author lb
 */
import LayoutIndex from '@/layout/Index';

const systemRouter = {
    path: '/dy',
    component: LayoutIndex,
    redirect: '/dy/user',
    name: 'dy',
    meta: {
        title: '抖音管理',
        icon: 'page',
        roles: ['dy']
    },
    children: [
        {
            path: 'user',
            component: () => import('@/views/Dy/User'),
            name: 'user-list',
            meta: { title: '用户列表', roles: ['dy-user-list'] }
        },
        {
            path: 'video',
            component: () => import('@/views/Dy/Video'),
            name: 'video-list',
            meta: { title: '视频管理', roles: ['dy-video-list'] }
        },
        {
            path: 'share',
            component: () => import('@/views/Dy/Share'),
            name: 'share-list',
            meta: { title: '分享发布管理', roles: ['dy-share-list'] }
        },
        {
            path: 'media',
            component: () => import('@/views/Dy/Media'),
            name: 'media-list',
            meta: { title: '素材列表', roles: ['dy-media-list'] }
        }
    ]
};

export default systemRouter;
