/**
 * 商品管理-路由配置
 * @author lb
 */
import LayoutIndex from '@/layout/Index';

const systemRouter = {
    path: '/goods',
    component: LayoutIndex,
    redirect: '/goods/info',
    name: 'goods',
    meta: {
        title: '商品管理',
        icon: 'page',
        roles: ['goods']
    },
    children: [
        {
            path: 'info',
            component: () => import('@/views/Goods/Info'),
            name: 'info-list',
            meta: { title: '商品信息', roles: ['goods-info-list'] }
        },
        {
            path: 'level',
            component: () => import('@/views/Goods/Level'),
            name: 'level-list',
            meta: { title: '商品分类', roles: ['goods-level-list'] }
        }
    ]
};

export default systemRouter;
