import request from '@/service/lib/request';
// 用户管理

/**
 * 用户 - 登录
 * @param {String} url 请求地址
 * @param {Object} data 请求参数
 * @author LiQingSong
 */
export function login(url, data) {
    return request({
      url: url,
      method: 'post',
      data
    });
}

/**
 * 用户 - 获取用户信息和权限
 * @author LiQingSong
 */
export function getInfo() {
    return request({
      url: '/admin_user/get_user_info',
      method: 'get'
    });
}

/**
 * 用户 - 退出
 * @author LiQingSong
 */
export function logout(url) {
    return request({
      url: url,
      method: 'post'
    });
}

/**
 * 分页查询
 * @param {*} data 
 * @returns 
 */
export function getUserList(data) {
  return request({
    url: '/admin_user/get_admin_user_page',
    method: 'post',
    data
  });
}

/**
 * 用户新增
 * @param {*} data 
 * @returns 
 */
export function addUser(data) {
  return request({
    url: '/admin_user/add_admin_user',
    method: 'post',
    data
  });
}

/**
 * 用户修改
 * @param {*} data 
 * @returns 
 */
export function updateUser(data) {
  return request({
    url: '/admin_user/update_admin_user',
    method: 'post',
    data
  });
}

/**
 * 用户删除
 * @param {*} data 
 * @returns 
 */
export function deleteUser(data) {
  return request({
    url: '/admin_user/delete_admin_user',
    method: 'post',
    data
  });
}

/**
 * 获取抖音授权url
 * @param {*} data 
 * @returns 
 */
export function getDyAuthUrl(data) {
  return request({
    url: '/admin_user/get_dy_qr_code_url',
    method: 'post',
    data
  });
}

/**
 * 抖音授权
 * @param {*} data 
 * @returns 
 */
export function dyAuth(data) {
  return request({
    url: '/admin_user/dy_auth',
    method: 'post',
    data
  });
}

/**
 * 删除权限
 * @param {*} data 
 * @returns 
 */
export function deleteAdminRight(data) {
  return request({
    url: '/admin_user/delete_admin_right',
    method: 'post',
    data
  });
}


/**
 * 全部用户
 * @param {*} data 
 * @returns 
 */
export function queryAll(data) {
  return request({
    url: '/admin_user/get_admin_user_list',
    method: 'post',
    data
  });
}


/**
 * 获取权限
 * @param {*} data 
 * @returns 
 */
export function getAdminRight(data) {
  return request({
    url: '/admin_user/get_admin_right',
    method: 'post',
    data
  });
}
