<template>
    <div class="indexlayout-right-footer">
        <div class="footer-copyright">
            <a href="https://v.douyin.com/iR7kBLTT/ 3@0.com 05/08" target="_blank">抖音主页</a>{{ '  ' }}
            <a href="" target="_blank">微博主页</a>{{ '  ' }}
            <a href="https://crypto-net.tech" target="_blank">猿驻宇宙</a>{{ '  ' }}
            Copyright © 2023 crypto-net.tech 版权所有 ICP证:{{ '  ' }}<a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2023037508号</a>
            {{ '  ' }}<a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2023037508号-1</a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LayoutIndexRightFooter'
};
</script>
<style lang="scss" scoped>
.indexlayout-right-footer {
    height: 20px;
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    color: #808695;
    .footer-links {
        margin-bottom: 10px;
        a {
            margin-right: 30px;
            color: #808695;
            text-decoration: none;
            &:last-child {
                margin-right: 0;
            }
            &:hover{
                color: #515a6e;
            }
        }
    }
}
</style>