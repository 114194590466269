/**
 * 系统管理-路由配置
 * @author lb
 */
import LayoutIndex from '@/layout/Index';

const systemRouter = {
    path: '/system',
    component: LayoutIndex,
    redirect: '/system/user',
    name: 'system',
    meta: {
        title: '系统管理',
        icon: 'page',
        roles: ['system']
    },
    children: [
        {
            path: 'user',
            component: () => import('@/views/System/User'),
            name: 'user-list',
            meta: { title: '用户管理' }
        },
        {
            path: 'role',
            component: () => import('@/views/System/Role'),
            name: 'role-list',
            meta: { title: '角色管理' }
        }
    ]
};

export default systemRouter;
