
// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

// 回显数据字典
export function selectDictLabel(datas = [], value = '', keyName = 'key', valueName = 'value') {
  var actions = [];
  Object.keys(datas).map((key) => {
    if (('' + datas[key][keyName]) === ('' + value)) {
      actions.push(datas[key][valueName]);
      return false;
    }
  });
  if (actions.length === 0) {
    actions = [value];
  }
  return actions.join('');
}

// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time);
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value]; }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}
/**
 * 复制
 * @param {*} content 
 */
export function handleCopy(content){
  let cInput = document.createElement("input");
  cInput.value = content;
  document.body.appendChild(cInput);
  cInput.select();
  document.execCommand("copy");
  document.body.removeChild(cInput);
}